.dashboard {
  padding: 49px 14px 16px;
  .coin-stats {
    margin-bottom: 40px;
    &-card {
      border: 2px solid $white;
      border-top-right-radius: 8px;
      border-top-left-radius: 8px;
      text-align: center;
      padding-top: 32px;
      h1 {
        font-weight: 700;
        color: $white;
      }
      p {
        font-weight: 500;
        color: $white;
      }
      img {
        width: 100%;
      }
    }
    .marketcap {
      background: linear-gradient(120deg, #a47cf1 10%, #1e197d)
    }
    .supply {
      background: linear-gradient(120deg, #ac82f7 10%, #5d03b2)
    }
    .holder {
      background: linear-gradient(120deg, #09bedd 10%, #5d03b2)
    }
    .price {
      background: linear-gradient(180deg, #09bedd 10%, #5d03b2)
    }
  }

  .ads {
    .announce-staking {
      .announce {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 16px;
        .title {
          background: $main-color url("../images/dashboard/polygon.png") no-repeat center;
          width: 100%;
          font-size: 18px;
          line-height: 25px;
          font-weight: 500;
          color: $white;
          text-align: center;
        }
        .announce-slide {
          background: $submain-color;
          border-radius: 8px;
          padding: 15px;
          .coming-soon {
            color: $white;
            position: relative;
            text-align: center;
            h2 {
              color: #707070;
              font-size: 29px;
              margin-bottom: 26px;
              span { color: #ffd954; }
            }
            p {
              font-size: 10px;
              font-weight: 500;
              width: 180px;
              margin: auto;
              margin-bottom: 32px;
            }
            .image-wrap {
              margin-bottom: 150px;
              // position: absolute;
              // left: calc(50% - 165px);
              // margin-top: 32px;
              .pool-ball {
                position: absolute;
                left: calc(50% - 259px);
                top: -30px;
                max-width: 519px;
              }
            }
            .coming-buy-ticket {
              position: absolute;
              top: 140px;
              left: calc(50% - 98px);
              width: 196px;
              background: $black;
              // margin: auto;
              border-radius: 12px;
              padding: 10px 8px;
              transform: rotate(1deg);
              h3 {
                font-size: 11px;
                font-weight: 500;
                margin-bottom: 14px;
              }
              .current-pool {
                text-align: center;
                padding: 10px;
                background: linear-gradient(to right, #ffdc70, #8a4702);
                border-radius: 18px;
                color: $black;
                margin-bottom: 8px;
                p {
                  font-size: 8px;
                  margin-bottom: 9px;
                }
                h5 {
                  font-size: 10px;
                  font-weight: 600;
                }
              }
              .count {
                font-size: 7px;
                span { font-weight: 600;}
                margin-bottom: 26px;
              }
              .dotteed {border-bottom: 8px dotted #cb02a1;}
              h1 {
                margin: 16px 0 10px 0;
                font-size: 11px;
                font-weight: 700;
                color: #fbbf19;
              }
              .ticket-input {
                margin-bottom: 2px;
                p {
                  font-size: 7px;
                  margin-bottom: 4px;
                }
                input {
                  background: #696989;
                  padding: 12px 8px;
                  font-size: 14px;
                  border: none;
                  border-radius: 10px;
                  color: $white;
                  outline: none;
                }
              }
              .price2ticket {
                display: flex;
                margin-bottom: 2px;
                p {margin: 0;}
              }
              .total {
                display: flex;
                margin-bottom: 15px;
                p {margin: 0;}
              }
              .buy-ticket {
                display: block;
                border-radius: 16px;
                padding: 14px;
                font-size: 8px;
                font-weight: 600;
                background: linear-gradient(to right,#00f9e2, #fe00f9);
              }
            }
          }
        }
      }
      .staking {
        img {
          width: 100%;
        }
      }
    }
    .fiat {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 12px;
      .title {
        border-bottom: 24px solid #111119;
        border-left: 24px solid transparent;
        border-right: 24px solid transparent;
        height: 0;
        width: 241px;
        font-size: 18px;
        line-height: 25px;
        font-weight: 500;
        color: $white;
        text-align: center;
        margin-bottom: 1px;
      }
      &-body {
        width: 100%;
        background: #111119;
        padding: 18px 24px;
        border-radius: $border-radius;
        .exchange {
          &-pay {
            display: flex;
            position: relative;
            flex-direction: row;
            justify-content: space-between;
            border: 1px solid #1d1c2c;
            border-radius: 16px;
            padding: 40px 20px;
            margin-bottom: 8px;
            .pay-left {
              h3 {
                font-size: 19px;
                font-weight: 500;
                color: $white;
                margin-bottom: 8px;
              }
              input {
                width: 100%;
                padding: 0;
                background: transparent;
                font-size: 21px;
                line-height: 28px;
                font-weight: 700;
                color: $white;
                outline: none;
              }
              h4 {
                font-size: 16px;
                font-weight: 400;
                color: #8a8a92;
              }
            }
            .pay-right {
              float: right;
              .pay-crypto {
                display: flex;
                flex-direction: row;
                width: max-content;
                min-width: 100%;
                align-items: center;
                border: 1px solid $white;
                border-radius: $border-radius;
                font-size: 14px;
                line-height: 18px;
                font-weight: 500;
                padding: 8px 12px;
                color: $white;
                margin-bottom: 8px;
                img {
                  width: 24px;
                  height: 24px;
                }
              }
              h4 {
                float: right;
                font-size: 12px;
                font-weight: 400;
                color: #8a8a92;
                width: max-content;
              }
            }
            .exchange-button {
              position: absolute;
              bottom: -26px;
              left: 43%;
              background: #242337;
              padding: 12px;
              border-radius: 50%;
            }
          }
          &-get {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            border: 1px solid #1d1c2c;
            border-radius: 16px;
            padding: 40px 20px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            border: 1px solid #1d1c2c;
            border-radius: 16px;
            padding: 40px 20px;
            margin-bottom: 54px;
            .get-left {
              h3 {
                font-size: 19px;
                font-weight: 500;
                color: $white;
                margin-bottom: 8px;
              }
              input {
                width: 100%;
                padding: 0;
                background: transparent;
                font-size: 21px;
                line-height: 28px;
                font-weight: 700;
                color: $white;
                outline: none;
              }
              h4 {
                font-size: 16px;
                font-weight: 400;
                color: #8a8a92;
              }
            }
            .get-right {
              float: right;
              .get-crypto {
                display: flex;
                flex-direction: row;
                width: max-content;
                min-width: 100%;
                align-items: center;
                border: 1px solid $white;
                border-radius: $border-radius;
                font-size: 14px;
                line-height: 18px;
                font-weight: 500;
                padding: 8px 12px;
                color: $white;
                margin-bottom: 8px;
                img {
                  width: 24px;
                  height: 24px;
                }
              }
              h4 {
                float: right;
                font-size: 12px;
                font-weight: 400;
                color: #8a8a92;
                width: max-content;
              }
            }
          }
        }
        .connect-wallet {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          padding: 25px 0;
          font-size: 25px;
          font-weight: 600;
          color: $white;
          background: #824cf4;
          border-radius: $border-radius;
          margin-bottom: 40px;
          &:disabled {
            background: #8a8a92;
          }
        }
        .share-earn,
        .chart,
        .buy-crypto {
          font-size: 14px;
          font-weight: 500;
          line-height: 17px;
          color: $white;
          margin-bottom: 16px;
        }
      }
    }
  }
}