.home {
  background: $submain-color;
  &-container {
    .home-top {
      background-image: url("../images/home/home-bg.png");
      background-color: $submain-color;
      .top-left {
        text-align: center;
        width: 100%;
        h1 {
          font-size: 71px;
          line-height: 98px;
          font-weight: 500;
          color: #a76df9;
          margin-bottom: 70px;
        }
        h4 {
          font-size: 40px;
          line-height: 40px;
          font-weight: 500;
          color: $white;
          margin-bottom: 70px;
        }
        .home-top-utilites-items {
          // display: flex;
          .home-top-utilites-item {
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            align-items: center;
            background: $submain-color;
            border-radius: 12px;
            margin-bottom: 18px;
            padding: 8px;
            img {
              width: 60px;
              height: 60px;
              margin-right: 16px;
            }
            h3 {
              font-size: 27px;
              font-weight: 500;
              line-height: 36px;
              color: $white;
            }
          }
        }
      }
      .top-right {
        position: relative;
        width: 100%;
        .contract-address {
          position: relative;
          bottom: 20%;
          width: fit-content;
          margin: auto;
          text-align: center;
          border-radius: 16px;
          padding: 24px 42px 16px;
          background: rgba(95,95,255,0.33725490196078434 );
          h5 {
            font-size: 14px;
            line-height: 18px;
            font-weight: 500;
            color: #c9b5e5;
            margin-bottom: 18px;
          }
          .contract {
            display: flex;
            flex-direction: row;
            padding: 8px;
            background: $submain-color;
            border-radius: 16px;
            img {
              width: 24px;
              height: 24px;
              margin-right: 8px;
            }
            p { color: $white;}
          }
        }
      }
    }
    .subtitle {
      position: relative;
      img {
        margin: auto;
      }
      h5 {
        font-size: 35px;
        line-height: 48px;
        font-weight: 500;
        color: $white;
        position: relative;
        bottom: 34px;
        text-align: center;
      }
    }

    .home-utilities {
      border-bottom: 2px solid $white;
      h5 {
        font-size: 21px;
        line-height: 28px;
        font-weight: 500;
        color: $white;
        margin-bottom: 28px;
      }
      .utilities-content {
        max-width: 1300px;
        margin: auto;
        .ticket {
          margin-bottom: 80px;
          // img {width: 590px;}
          .ticket-description {
            display: flex;
            width: 50%;
            align-items: center;
            div:first-child {
              .caption {
                color: #8948da;
                font-size: 16px;
                margin-bottom: 16px;
              }
              h5 {
                font-weight: 700;
                color: $white;
                font-size: 24px;
              }
              .text {
                color: $white;
                font-size: 16px;
              }
            }
          }
          .coming-soon {
            color: $white;
            position: relative;
            text-align: center;
            h2 {
              color: #707070;
              font-size: 29px;
              margin-bottom: 26px;
              span { color: #ffd954; }
            }
            p {
              font-size: 10px;
              font-weight: 500;
              margin: auto;
              margin-bottom: 32px;
            }
            .image-wrap {
              margin-bottom: 150px;
              .pool-ball {
                position: absolute;
                left: calc(50% - 259px);
                top: -30px;
                max-width: 519px;
              }
            }
            .coming-buy-ticket {
              position: absolute;
              top: 10px;
              left: calc(50% - 98px);
              width: 196px;
              background: $black;
              // margin: auto;
              border-radius: 12px;
              padding: 10px 8px;
              transform: rotate(1deg);
              h3 {
                font-size: 11px;
                font-weight: 500;
                margin-bottom: 14px;
              }
              .current-pool {
                text-align: center;
                padding: 10px;
                background: linear-gradient(to right, #ffdc70, #8a4702);
                border-radius: 18px;
                color: $black;
                margin-bottom: 8px;
                p {
                  font-size: 8px;
                  margin-bottom: 9px;
                }
                h5 {
                  font-size: 10px;
                  font-weight: 600;
                  margin: 0;
                }
              }
              .count {
                font-size: 7px;
                span { font-weight: 600;}
                margin-bottom: 26px;
              }
              .dotteed {border-bottom: 8px dotted #cb02a1;}
              h1 {
                margin: 16px 0 10px 0;
                font-size: 11px;
                font-weight: 700;
                color: #fbbf19;
              }
              .ticket-input {
                margin-bottom: 2px;
                p {
                  font-size: 7px;
                  margin-bottom: 4px;
                }
                input {
                  background: #696989;
                  padding: 12px 8px;
                  font-size: 14px;
                  border: none;
                  border-radius: 10px;
                  color: #bfb9b9;
                  outline: none;
                }
              }
              .price2ticket {
                display: flex;
                margin-bottom: 2px;
                p {margin: 0;}
              }
              .total {
                display: flex;
                margin-bottom: 15px;
                p {margin: 0;}
              }
              .buy-ticket {
                display: block;
                border-radius: 16px;
                padding: 10px;
                font-size: 8px;
                font-weight: 600;
                background: linear-gradient(to right,#00f9e2, #fe00f9);
              }
            }
          }
        }
        .handle {
          margin-bottom: 56px;
          img {
            width: 330px;
          }
          .handle-description {
            display: flex;
            width: 50%;
            align-items: flex-start;
            flex-direction: column;
              .caption {
                color: #8948da;
                font-size: 16px;
                margin-bottom: 16px;
              }
              h5 {
                text-align: left;
                font-weight: 700;
                color: $white;
                font-size: 24px;
              }
              .text {
                text-align: left;
                color: $white;
                font-size: 16px;
              }
          }
        }
        .payment {
          margin-bottom: 56px;
          img {
            width: 240px;
          }
          .payment-description {
            display: flex;
            width: 50%;
            align-items: flex-start;
            flex-direction: column;
              .caption {
                color: #8948da;
                font-size: 16px;
                margin-bottom: 16px;
              }
              h5 {
                text-align: left;
                font-weight: 700;
                color: $white;
                font-size: 24px;
              }
              .text {
                text-align: left;
                color: $white;
                font-size: 16px;
              }
          }
        }
        .investment {
          margin-bottom: 56px;
          img {
            width: 318px;
          }
          .investment-description {
            display: flex;
            width: 50%;
            align-items: flex-start;
            flex-direction: column;
              .caption {
                color: #8948da;
                font-size: 16px;
                margin-bottom: 16px;
              }
              h5 {
                text-align: left;
                font-weight: 700;
                color: $white;
                font-size: 24px;
              }
              .text {
                text-align: left;
                color: $white;
                font-size: 16px;
              }
          }
        }
      }
    }
    .home-roadmap {
      padding: 48px 0;
      border-bottom: 2px solid $white;
      &-container {
        width: 1300px;
        margin: auto;
      }
    }
    .home-news {
      padding: 48px 0;
      &-container {
        .news-item {
          padding: 8px;
          &-container {
            background: $white;
            border-radius: 8px;
            padding: 4px;
            img {
              width: 100%;
              height: 200px;
              border-radius: 8px;
              margin-bottom: 16px;
            }
            .news-connect-btn {
              background: $submain-color;
              padding: 8px;
              color: $white;
              font-size: 16px;
              border-radius: 8px;
            }
          }
        }
        a {
          text-align: center;
          color: #0ac6e5;
        }

        .stay-connected {
          margin-top: 130px;
          .social-connect {
            background: #474d83;
            border-radius: 16px;
            padding: 36px 28px;
            color: #bdc2ff;
            margin-bottom: 18px;
            &-icon {
              display: flex;
              flex-direction: row;
              justify-content: flex-start;
              align-items: center;
              margin-bottom: 16px;
              h4 {
                font-size: 26px;
                font-weight: 500;
              }
              img {
                width: 41px;
                height: 41px;
                margin-right: 16px;
              }
            }
            p {
              font-size: 15px;
              font-weight: 16px;
            }
          }
          h3 {
            color: $white;
            font-size: 36px;
            font-weight: 700;
          }
          p {
            color: $white;
            font-size: 18px;
            margin-bottom: 42px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 640px) {
  .news {
    width: 90vw;
  }
}