.footer {
  background: #3e4478;
  .social-contact {
    .footer-logo {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 27px;
      img {
        width: 38px;
        height: 38px;
        margin-right: 8px;
      }
      h5 {
        font-size: 15px;
        color: $white;
      }
    }
    .social-logo {
      display: flex;
      justify-content: flex-start;
      a {
        display: block;
      }
      img {
        width: 23px;
        height: 23px;
        margin-right: 18px;
      }
    }
  }
  .contact-us {
    h5 {
      color: $white;
      margin-bottom: 12px;
    }
    a {
      display: block;
      color: $white;
      font-size: 15px;
      margin-bottom: 12px;
    }
  }
  .token-info {
    h5 {
      color: $white;
      font-size: 12px;
      margin-bottom: 12px;
    }
    h4 {
      color: #e6e254;
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 12px;
    }
    .contract-address {
      width: max-content;
      background: #23264c;
      border-radius: 20px;
      padding: 8px;
      color: $white;
      margin-bottom: 16px;
      img {
        width: 24px;
        height: 24px;
        margin-right: 8px;
      }
      .copy {
        margin-left: 8px;
        width: 19px;
        height: 19px;
      }
    }
    .binance {
      width: fit-content;
      padding: 8px 16px;
      background: #122135;
      border-radius: 16px;
    }
  }
}