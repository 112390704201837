.nftstake {
  position: relative;
  display: flex;
  align-items: center;
  &-container {
    // margin-top: 8%;
    .harvest-card {
      .earnable-token,
      .tvl-apr-token {
        justify-content: space-between;
        padding: 30px 0;
        border-bottom: 2px solid #182b6d;
        p {
          color: $white;
          font-size: 16px;
          font-weight: 500;
        }
      }
      .earnable-token {
        display: flex;
        img {
          width: 20px;
          height: 20px;
          margin-left: 8px;
        }
      }
      .holding {
        margin-top: 26px;
        .my-holding,
        .pending-reward {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 16px 20px;
          color: $white;
          width: 100%;
          font-size: 20px;
          font-weight: 500;
          border: 2px solid #771150;
          border-radius: 8px;
          margin-bottom: 20px;
          img {
            width: 20px;
            height: 20px;
            margin-left: 8px;
          }
        }
      }
      .harvest-btn {
        width: 100%;
        padding: 16px 20px;
        color: $white;
        font-size: 20px;
        font-weight: 500;
        border: 2px solid #771150;
        border-radius: 8px;
      }
    }
    .stake-card {
      display: flex;
      flex-direction: column;
      .stake-button-group {
        display: flex;
        width: 100%;
        margin-bottom: 57px;
        .stake-select-btn,
        .unstake-select-btn {
          flex: 1;
          padding: 10px 0;
          text-align: center;
          color: $white;
          font-size: 16px;
          font-weight: 500;
          border-bottom: 2px solid #361e4d;
          transition: 0.3s all linear;
          &.active {
            border-bottom: 2px solid #e2177f;
          }
        }
      }
      .stake-balance {
        color: #95969b;
        font-size: 15px;
        font-weight: 500;
        margin-bottom: 8px;
      }
      .stake-input {
        display: flex;
        border: 1px solid #293042;
        padding: 4px;
        margin: 16px 0;
        align-items: center;
        border-radius: 8px;
        background: #14182c;
        .inputWithButton{
          flex: 1 1 auto;
          position: relative;
          padding-right: 40px;
          input {
            padding: 5px;
            background: transparent;
            outline: none;
            color: $white;
            font-size: 16px;
            font-weight: 500;

          }
          button {
            background: #2e358d;
            color: $white;
            font-size: 12px;
            font-weight: 500;
            line-height: 14px;
            padding: 8px;
            border-radius: 4px;
            position: absolute;
            right: 0;
            // top : 5px;
          }
        }
        .stake-token {
          display: flex;
          flex-wrap: wrap;
          margin-left: 8px;
          padding-left: 8px;
          border-left: 1px solid #293042;
          img {
            width: 26px;
            height: 26px;
            margin-right: 4px;
          }
          p {
            color: $white;
            font-size: 16px;
          }
        }
      }
      .stake-btn {
        width: 100%;
        padding: 18px;
        bottom: 0;
        background: #14182c;
        text-align: center;
        font-size: 18px;
        font-weight: 500;
        color: $white;
        border-radius: 8px;
        border: 2px solid #293042;
      }
    }
  }
}