
$main-color:      #260c50 !default;
$submain-color:   #15152e !default;

$blue:      #3470F2 !default;
$black:     #000 !default;
$gold:      #ffdd00 !default;
$white:     #fff !default;
$error:     #ed4b9e !default;

$border-radius: 8px !default;

@font-face {
    font-family: 'exo';
    src: url('../fonts/Exo2.0-Medium.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'sofia';
    src: url('../fonts/Sofia Pro Medium Az.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'lato';
    src: url('../fonts/Lato-Bold.ttf') format('opentype');
    font-weight: normal;
    font-style: normal;
}