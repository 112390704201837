.sidebar {
  background: $submain-color;
  border-top: 4px solid $white;
  border-bottom: 4px solid $white;
  border-right: 4px solid $white;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  min-height: 100vh;

  &-top {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin: 20px;
    img {
      width: 81px;
      height: 81px;
      margin-right: 18px;
    }
    h1 {
      color: $white;
      font-size: 38px;
      font-family: 'exo';
      line-height: 40px;
    }
  }
  &-body {
    display: flex;
    flex-direction: column;
    list-style-type: none;
    padding: 0;
    .sidebar-item {
      padding-left: 40px;
      margin-left: 8px;
      .item {
        display: flex;
        text-decoration: none;
        align-items: center;
        padding: 24px 0;
        img {
          width: 31px;
          height: 31px;
          margin-right: 18px;
        }
        p {
          font-size: 21px;
          line-height: 21px;
          // font-family: 'poppins-medium';
          // font-weight: 500;
          color: $white;
          margin: 0;
        }
      }
      &.active {
        background: $white;
        border-top-left-radius: $border-radius;
        border-bottom-left-radius: $border-radius;
        p {
          color: #504da6;
        }
      }
    }
  }
}