.header {
  .user-info-btn {
    position: relative;
    top: -8px;
    width: 175px;
    height: 38px;
    background: $white;
    border-radius: 50% / 0 0 100% 100%;
  }

  &-container {
    // display: flex;
    // flex-direction: row;
    // justify-content: flex-end;
    // align-items: center;
    background: $submain-color;
    border-bottom: 4px solid $white;
    padding: 12px 20px;
    .hidden-menu {
      .aeterna-price {
        padding: 8px 20px;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-right: 30px;
        border: 1px solid #373746;
        border-radius: 8px;
        img {
          width: 20px;
          height: 20px;
          margin-right: 12px;
        }
        h4 {
          font-size: 18px;
          font-weight: 700;
          line-height: 18px;
          font-family: 'segoe ui';
          color: $white;
          margin: 0;
        }
      }
      .solidproof {
        height: 36px;
        width: auto;
        margin-right: 30px;
      }
      .obsidian {
        height: 37px;
        width: auto;
        margin-right: 26px;
      }
    }
    .certik {
      height: 36px;
      width: auto;
      margin-right: 26px;
    }
    .connect-wallet {
      background: #5957d5;
      padding: 10px 16px;
      margin-right: 16px;
      font-size: 16px;
      font-family: "segoe ui";
      font-weight: 600;
      line-height: 12px;
      color: $white;
      border: none;
      border-radius: 6px;
      cursor: pointer;
    }
    .user-info {
      height: 36px;
      width: auto;
      background: transparent;
      border: none;
      padding: 0;
      cursor: pointer;
    }
  }
  &-home-container {
    background: transparent;
    // display: flex;
    // flex-direction: row;
    // justify-content: space-between;
    // align-items: center;
    border-bottom: 4px solid $white;
    // padding: 23px 20px 15px;
    .header-home-left {
      display: flex;
      flex-direction: row;
      align-items: center;
      img {
        width: 49px;
        height: auto;
        margin-right: 10px;
      }
      h3 {
        font-size: 19px;
        line-height: 25px;
        font-weight: 500;
        color: $white;
      }
    }
    .header-home-right {
      .home-solidproof {
        background: #122135;
        min-width: 179px;
        border-radius: 12px;
        text-align: center;
        img {
          height: 44px;
          padding: 2px 8px;
        }
      }
      .home-certik {
        min-width: 179px;
        background: #a154fb;
        border-radius: 12px;
        img {
          height: 44px;
          padding: 2px 8px;
        }
      }
      .home-buy-aeterna {
        background: linear-gradient(to right, #ff357d, #ff6f33);
        border-radius: 12px;
        padding: 10px 26px;
        font-size: 20px;
        font-weight: 500;
        line-height: 25px;
        color: $white;
      }
      .home-dashboard-btn {
        background: linear-gradient(to right, #ac82f7, #6300b7);
        border-radius: 12px;
        padding: 10px 26px;
        font-size: 20px;
        font-weight: 500;
        line-height: 25px;
        color: $white;
      }
    }
  }
}