.presale {
  &-header {
    .logo {
      width: 120px;
    }
  }
  &-main {
    height: auto;
    min-height: calc(100vh - 160px);
  }
  .connect-button {
    border: 2px solid $black;
    border-radius: 32px;
  }
  .content {
    border: 24px solid #1b0938;
  }
  .progress {
    height: 32px;
    width: 100%;
    background: #1b0938;
    border-radius: 32px;
    .value {
      height: 32px;
      background: #5d0067;
      border-radius: 32px;
    }
  }
  .pink-color {background: #7800a2;}
  .pink-bold {background: #3b0a65;}
  .pink-2xbold {background: #41086b;}
  .sky-light {background: #1a9def;}
  .sky-dark {background: #7089da;}
}

@media only screen and (max-width: 500px) {
  .presale-title {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    .title {
      margin: 16px 0;
    }
  }
  .audit {
    display: none;
  }
}
