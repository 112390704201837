.stake {
  padding: 36px;

  &-controller {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background: #16182d77;
    border-radius: 8px;
    padding: 8px 16px;
    .list-style {
      margin-right: 5%;
    }
    .active-button-group {
      display: flex;
      background: #eeeaf4;
      border-radius: 8px;
      padding: 2px;
      button {
        min-width: 68px;
        color: #7a6eaa;
        font-size: 14px;
        font-weight: 500;
        padding: 8px 12px;
        border-radius: 8px;
        transition: 0.3s all linear;
        &.active {
          background: #7a6eaa;
          color: #eeeaf4;
        }
      }
    }
  }
}